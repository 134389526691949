.headerLady {
    margin-top: -66px;
    margin-left: 90px;
    margin-right: 90px;
}

@media (max-width: 767px) {
    .connect-addresse {
        width: auto !important;
    }

    .menu-connect {
        width: auto !important;
    }

    .ticket-loterry {
        margin-top: 60px;
    }

    .billet {
        margin-top: 30px;
    }

    .width-prix {
        font-size: 45px;
    }


}

.connect-addresse {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-connect {
    background-color: rgb(66, 75, 75);
    color: white;
    text-align: center;
}

#wallet_connect {
    background: #7bbbd333;
}

#wallet_account,
#acheter_ticket {
    background: #7bbbd333;
    color: rgba(255, 255, 255, 0.771);
}

.contentModal {
    border-radius: 40px;
}

.leaderboard-area table tr th,
.leaderboard-area table tr td {
    width: 25% !important;
}

.run {
    background: #28a745;
}

.wallet-connect {
    border-bottom: #e0ece3 solid 1px;
}

.wallet-connect1 {

    border-top: #e0ece3 solid 1px;

}

.wallet-connect:hover {
    background-color: rgba(208, 220, 232, 0.289);
    cursor: pointer;
}

.button-wallet {
    background: rgb(66, 75, 75);
    border: rgb(66, 75, 75) solid 0px !important;
    color: white;
    width: 100%
}

.button-loterry {
    background: rgba(67, 145, 38, 0.18);
    border: rgba(66, 75, 75, 0.027) solid 1px !important;
    color: white;
    width: 100%;
    border-radius: 4px;
    font-weight: bold;
}

.button-loterry:hover {
    background: rgba(66, 75, 75, 0);
    border: rgba(66, 75, 75, 0.027) solid 0px !important;
    color: rgb(107, 127, 216);
    width: 100%
}

.button-wallet:hover {
    background: rgba(208, 220, 232, 0.289);
    border: rgba(208, 220, 232, 0.289) solid 0px !important;
    color: white;
    width: 100%
}

.wallet-warning {
    background: rgba(255, 178, 55, 0.098) none repeat scroll 0% 0%;
    border: 1px solid rgb(255, 178, 55);

}

.wallet-adress {
    background: #1f1f25;
    padding: 16px;
    margin: 15px;
}

.wallet-button {
    width: 50%;
    align-items: center;
    border: 0px none;
    border-radius: 16px;
    box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    -moz-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: currentcolor none 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 32px;
    padding: 0px 16px;
    background-color: rgb(208, 220, 232);
    color: rgb(12, 14, 27);
}

.cursorWallet {
    cursor: pointer;

}

.nav-wallet {
    cursor: pointer;
    padding: 5px;
    border-radius: 6px;
    width: 100%;
    text-align: center;
}

.nav-wallet:hover {
    cursor: pointer;
    background: #848c8a45;
    color: white;
    padding: 5px;
    border-radius: 6px;
}

.menu-acceuil {
    background-color: rgb(14, 56, 104);
    color: white;
    text-align: center;

}

.input-calculator {
    width: 100%;
    font-size: 20px;
    color: white;
    background-color: transparent;
    border-color: transparent;
    outline: transparent none medium;
}

.input-calculator:focus {
    width: 100%;
    font-size: 20px;
    color: white;
    background-color: transparent;
    border-color: transparent;
    outline: transparent none medium;
}

.prix-gagner {
    display: inline-block;
    background: rgba(0, 0, 0, 0) linear-gradient(to right, rgb(136, 156, 153), rgb(5, 48, 54), rgb(207, 160, 207)) repeat scroll 0% 0% padding-box text;
    -webkit-text-fill-color: transparent;
    color: rgb(208, 220, 232);
    line-height: 1.5;
    font-weight: 800;
    font-size: 50px;
}


.chrono-loterry {
    background: #f1edede0;
    color: #1200ec;
    padding: 6px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bolder;
}

.ticket {
    padding: 30px;
    border: solid 1px;
    text-align: center;
    background: #f9f7f4c4;
    color: black;
    border-radius: 32px;
}

.button-ticket {
    margin-top: -28px;
    border: solid 1px;
    border-radius: 10px;
    color: white;
    background: #0047FF;
    cursor: pointer;
    padding: 5px;
}

.ticket-input {
    border-radius: 20px;
    margin-top: 10px;
    background: #384268d6;

}

.ligne {
    border: solid 1px #a1addf4d;
    margin-top: 12px;
}

.number {
    background: red;
    border-radius: 50%;
    padding: 6px;
    width: 30px;
    height: 30px;
    color: white
}